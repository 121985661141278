import LogoView from './pixi/LogoView.js';
import FirstView from './FirstView.js';
import Statement from './Statement.js';
import People from './People.js';
import News from './News.js';
import BottomArrow from './BottomArrow.js';
import * as Util from './util/Util.js';


class Home
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Home
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //変数初期化
    this.pageIndex = 0;
    this.isLock = false;
    this.animationFlag = false;
    this.fitScrollMode = true;
    this.scrollPauseMode = false;
    this.lastWheelStamp = 0;
    this.deltaY = 0;
    this.wheelTrigger = false;
    this.autoScrollFlag = false;
    this.before = 0;
    this.touchStartY = 0;
    this.touchEndY = 0;

    //ロゴ
    this.logo = new LogoView();
    this.logo.on("NAV_SELECTED", this.onNavSelected.bind(this));

    //白ベース参照
    this.base = $("#header-base");

    //UI
    this.langSwitcher = $(".wpm-language-switcher");
    this.burgerBtn = $(".btn-burger");
    this.bArrow = $("#bottom-arrow");

    //ファーストビュー
    this.firstView = new FirstView();
    this.firstView.on("ON_SLIDE_CHANGE", this.onSlideChange.bind(this));
    // this.firstView.on("ON_SLIDE_FIX", this.onSlideFix.bind(this));

    //下矢印
    this.bottomArrow = new BottomArrow();
    this.bottomArrow.on("BOTTOM_SELECTED", ()=>{
      this.changePage.bind(this)(1);
    });

    //ループ処理開始
    this.loop.bind(this)();
  }

  /**
   * 初期化
   */
  init(){

    //ページを先頭にスクロール
    $('html,body').animate({ scrollTop: 0 }, '1');

    //スクロール方向制御用変数
    this.before = 0;

    //ロゴ初期化
    this.logo.init();

    //ステートメント
    this.statement = new Statement();

    //PEOPLE
    this.people = new People();

    //NEWS
    this.news = new News();
  }

  /**
   * スタート
   */
  start(){

    //ロゴのスプラッシュ表示
    // this.logo.once("LOGO_FIX", ()=>{
      // //下→表示
      // this.bottomArrow.show(0);
      // //スクロール制御開始
      // this.startScroll.bind(this)();
      // //FVスタート
      // this.firstView.start();
      // //ロゴの色を変更
      // this.logo.updateColor(this.firstView.getCurrentColor());
      // //UIの色を変更
      // this.updateUIColor(this.firstView.getCurrentColor());
    // });
    // this.logo.showSplash();
    this.logo.bounce();

    setTimeout(()=>{
      //下→表示
      this.bottomArrow.show(0);
      //スクロール制御開始
      this.startScroll.bind(this)();
      //FVスタート
      this.firstView.start();
      //ロゴの色を変更
      this.logo.updateColor(this.firstView.getCurrentColor());
      //UIの色を変更
      this.updateUIColor(this.firstView.getCurrentColor());
    }, 500);
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	NAVIGATION
	// ---------------------------------------------------------------------------------------------------------

  /**
   * ナビゲーション選択
   */
  onNavSelected(tag){

    //自動スクロールフラグを立てる
    this.autoScrollFlag = true;

    //ロック
    this.isLock = true;

    //ページインデックス
    this.pageIndex = 0;
    this.fitScrollMode = true;

    //ロゴの表示
    this.logo.showTitle();
    this.base.removeClass("show");

    //下矢印の表示
    this.bottomArrow.show(0.5);

    //スクロール
    TweenMax.to($("html,body"), 1, {"scrollTop":$(tag).offset().top, ease:Power2.easeInOut, onComplete:()=>{
      this.autoScrollFlag = false;
      this.isLock = false;
    }});
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	SCROLL CONTROLL
	// ---------------------------------------------------------------------------------------------------------

  /**
   * スクロール停止
   */
  startScroll(){

    //スクロール可能にする
    $("body").css("overflow-y", "auto");

    //PC用
    this.onWheel = this._onWheel.bind(this);
		var scroll_event = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
		document.addEventListener(scroll_event, this.onWheel, { passive: false });

		//SP用
    this.onTouchStart = this._onTouchStart.bind(this);
    this.onTouchMove = this._onTouchMove.bind(this);
    this.onTouchEnd = this._onTouchEnd.bind(this);
    document.addEventListener('touchstart', this.onTouchStart, { passive: false });
    document.addEventListener('touchmove', this.onTouchMove, { passive: false });
    document.addEventListener('touchend', this.onTouchEnd, { passive: false });

    //スクロールのハンドリング
    $(window).scroll(this.onScroll.bind(this));
  }


  /**
   * マウスホイール
   */
  _onWheel(e){

    //マニュアルスクロール停止（フィットスクロール）
    if(this.fitScrollMode && e.cancelable) e.preventDefault();

    //最後にホイールイベントが発生した時間
    if(!this.wheelTrigger)
    {
      this.wheelTrigger = true;
    }
    this.lastWheelStamp = new Date().getTime();

    //スクロール方向
    this.deltaY = e.deltaY ? e.deltaY : e.wheelDelta ? -e.wheelDelta : (e.detail);

    //ページ変更
    if(this.deltaY > 0){
      this.changePage.bind(this)(1);
    }else if(this.deltaY < 0){
      this.changePage.bind(this)(-1);
    }
  }


  _onTouchStart(e){

    //タッチ開始位置を保存
    this.touchStartY = e.touches[0].clientY;
    this.touchEndY = this.touchStartY;
  }

  _onTouchMove(e){

    //マニュアルスクロール停止（フィットスクロール）
    if(this.fitScrollMode && e.cancelable) e.preventDefault();

    //タッチ開始位置を保存
    this.touchEndY = e.touches[0].clientY;
  }

  _onTouchEnd(e){

    //スクロール
    let saY = this.touchEndY - this.touchStartY;
    if(saY < -30){
      this.changePage.bind(this)(1);
    }else if(saY > 30){
      this.changePage.bind(this)(-1);
    }
  }

  // ---------------------------------------------------------------------------------------------------------
	// --	フィットスクロール
	// ---------------------------------------------------------------------------------------------------------

  changePage(vec){

    //ロック中は無視
    if(this.isLock || !this.fitScrollMode) return;

    //前のページINDEX
    let prevPageIndex = this.pageIndex;

    //インデックス変更
    this.pageIndex += vec;
    if(this.pageIndex < 0){
      this.pageIndex = 0;
      return;
    }else if(this.pageIndex > 2){
      this.pageIndex = 2;
      return;
    }

    //ロック
    this.isLock = true;

    //フィットスクロール
    let opY = 0;
    switch (this.pageIndex) {
      case 0://FirstVIEW
        opY = 0;
        this.logo.showTitle();
        this.logo.updateColor(this.firstView.getCurrentColor());
        this.updateUIColor(this.firstView.getCurrentColor());
        this.bottomArrow.show(0.5);
        this.base.removeClass("show");
        break;
      case 1://STATEMENT
        opY = $("#statement").offset().top;
        this.fitScrollMode = true;
        this.logo.hideTitle();
        this.logo.updateColor(Util.getUIColor(""));
        this.updateUIColor(Util.getUIColor(""));
        this.bottomArrow.hide();
        this.base.removeClass("show");
        break;
      case 2://MEMBERS
        opY = $("#people").offset().top;
        break;
      default:
        opY = 0;
    }
    this.animationFlag = true;
    TweenMax.to($("html,body"), 1, {"scrollTop":opY, ease:Power2.easeInOut, onComplete:()=>{
      this.animationFlag = false;
      if(this.pageIndex == 1) this.statement.show();
      if(this.pageIndex == 2)
      {
        this.people.show();
        this.fitScrollMode = false;
        this.base.addClass("show");
      }
    }});
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	MV上UIの色変更関連
	// ---------------------------------------------------------------------------------------------------------

  onSlideChange(e){
    if(this.pageIndex == 0)
    {
      this.logo.updateColor(this.firstView.getCurrentColor());
      this.updateUIColor(this.firstView.getCurrentColor());
    }
  }

  updateUIColor(color){

    //言語選択ボタン
    this.langSwitcher.removeClass("white");
    if(color.ui == "white") this.langSwitcher.addClass("white");

    //バーガーボタン
    this.burgerBtn.removeClass("white");
    if(color.ui == "white") this.burgerBtn.addClass("white");

    //下矢印
    this.bArrow.removeClass("white");
    if(color.ui == "white") this.bArrow.addClass("white");
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	Contactへジャンプ
	// ---------------------------------------------------------------------------------------------------------

  jumpToContact(){

    this.logo.hideTitle();
    this.bottomArrow.hide();

    this.animationFlag = true;
    TweenMax.killTweensOf($("html,body"));
    TweenMax.to($("html,body"), 1, {"scrollTop":$(document).height(), ease:Power2.easeInOut, onComplete:()=>{
      this.animationFlag = false;
      this.pageIndex = 2;
      this.people.show();
      this.fitScrollMode = false;
      this.base.addClass("show");
    }});
  }



  // ---------------------------------------------------------------------------------------------------------
	// --	SCROLL処理
	// ---------------------------------------------------------------------------------------------------------

  onScroll(e){

    if(!this.fitScrollMode && ($(window).scrollTop() < $("#people").offset().top) && !this.autoScrollFlag)
    {
      this.fitScrollMode = true;
      this.changePage.bind(this)(-1);
      return;
    }

    //下についたかどうかのフラグ
    let docHeight = $(document).innerHeight(); //ドキュメントの高さ
    let windowHeight = $(window).innerHeight(); //ウィンドウの高さ
    let pageBottom = docHeight - windowHeight; //ドキュメントの高さ - ウィンドウの高さ
    let bottomFlag = (pageBottom <= $(window).scrollTop());

    //チェリーの制御
    let margin = (window.innerWidth <= 768)?40:20;
    if(this.fitScrollMode || $(window).scrollTop() < ($("#people").offset().top + margin) || bottomFlag)
    {
      this.logo.showHeader();
    }
    else
    {
      let after = $(window).scrollTop();
      if(after < 0) after = 0;
      if(this.before > after) {
        this.logo.showHeader();
      }
      else if(this.before < after) {
        this.logo.hideHeader();
      }
      this.before = after;
    }

  }


  // ---------------------------------------------------------------------------------------------------------
	// --	LOOP処理
	// ---------------------------------------------------------------------------------------------------------

  loop(){

    if(this.wheelTrigger)
    {
      let currentTimeStamp = new Date().getTime();
      let spendTime = currentTimeStamp - this.lastWheelStamp;
      if(spendTime > 100){
        this.wheelTrigger = false;
        // console.log("wheel停止");
      }
    }

    if(this.isLock && !this.animationFlag && !this.wheelTrigger)
    {
      // console.log("ロック解除");
      this.isLock = false;
    }

    //NEWsのループ
    if(this.news) this.news.loop();

    // console.log("loop");
    window.requestAnimationFrame(this.loop.bind(this));
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default Home;
