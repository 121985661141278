let EventEmitter = require("events").EventEmitter;
import * as Util from './util/Util.js';


class BottomArrow extends EventEmitter
{
  // ---------------------------------------------------------------------------------------------------------
	// --	BottomArrow
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    super();

    this.arrow = $("#bottom-arrow");

    TweenMax.set(this.arrow, {opacity:0, y:40});
    this.arrow.hide();

    let holder = this;
    this.arrow.find(">a").click(function(e){
      e.preventDefault();
      holder.emit("BOTTOM_SELECTED");
    });
  }

  show(delay = 0){
    if(Util.isSP())
    {
      this.arrow.show();
      TweenMax.killTweensOf(this.arrow);
      TweenMax.to(this.arrow, 0.4, {opacity:1, y:0, delay:delay});
    }
  }

  hide(delay = 0){
    TweenMax.killTweensOf(this.arrow);
    TweenMax.to(this.arrow, 0.4, {opacity:0, y:40, delay:delay, onComplete:()=>{
      this.arrow.hide();
    }});
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default BottomArrow;
