class LangNav
{
  // ---------------------------------------------------------------------------------------------------------
	// --	LangNav
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    this.lang = $(".wpm-language-switcher");

    // TweenMax.set(this.lang, {opacity:0, x:40});
    // this.lang.hide();
  }

  show(delay = 0){
    // this.lang.show();
    // TweenMax.killTweensOf(this.lang);
    // TweenMax.to(this.lang, 0.4, {opacity:1, x:0, delay:delay});
  }

  hide(delay = 0){
    // return;
    // TweenMax.killTweensOf(this.lang);
    // TweenMax.to(this.lang, 0.4, {opacity:0, x:40, delay:delay, onComplete:()=>{
    //   this.lang.hide();
    // }});
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default LangNav;
