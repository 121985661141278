import * as Config from '../constant/Config.js';
import * as Util from '../util/Util.js';


class LogoModel
{
  // ---------------------------------------------------------------------------------------------------------
	// --	LogoModel
	// ---------------------------------------------------------------------------------------------------------

  constructor(status){

    switch (status) {
      case 0:
        this.opCherryS = (Util.isSP())?Config.SIZE_NORMAL_SP:Config.SIZE_NORMAL_PC;
        this.opCherryY = (Util.isSP())?Config.POS_NORMAL_SP:Config.POS_NORMAL_PC;
        this.opTitleS = (Util.isSP())?Config.TITLE_SIZE_NORMAL_SP:Config.TITLE_SIZE_NORMAL_PC;
        this.opTitleY = (Util.isSP())?Config.TITLE_POS_NORMAL_SP:Config.TITLE_POS_NORMAL_PC;
        break;
      case 1:
        this.opCherryS = (Util.isSP())?Config.SIZE_HEADER_SP:Config.SIZE_HEADER_PC;
        this.opCherryY = (Util.isSP())?Config.POS_HEADER_SP:Config.POS_HEADER_PC;
        this.opTitleS = (Util.isSP())?Config.TITLE_SIZE_HEADER_SP:Config.TITLE_SIZE_HEADER_PC;
        this.opTitleY = (Util.isSP())?Config.TITLE_POS_HEADER_SP:Config.TITLE_POS_HEADER_PC;
        break;
      case 2:
        this.opCherryS = (Util.isSP())?Config.SIZE_SMALL_SP:Config.SIZE_SMALL_PC;
        this.opCherryY = (Util.isSP())?Config.POS_SMALL_SP:Config.POS_SMALL_PC;
        this.opTitleS = (Util.isSP())?Config.TITLE_SIZE_HEADER_SP:Config.TITLE_SIZE_HEADER_PC;
        this.opTitleY = (Util.isSP())?Config.TITLE_POS_HEADER_SP:Config.TITLE_POS_HEADER_PC;
        break;
      default:
    }
  }
}

export default LogoModel;
