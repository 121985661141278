import * as Util from './util/Util.js';

class WorksDetail
{
  // ---------------------------------------------------------------------------------------------------------
	// --	WorksList
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //DOM参照
    this.block = $(".detail-block");
    this.mainvisual = $(".detail-mainvisual");
    this.mainvisualImg = this.mainvisual.find(">img");

    //スクロールをハンドリング
    $(window).scroll(this.onScroll.bind(this));
  }

  init(){

    //リサイズをハンドリング
    $(window).resize(this.resize.bind(this));
    this.resize();
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	SCROLL処理
	// ---------------------------------------------------------------------------------------------------------

  onScroll(e){

    //メインビジュアルのパララックス効果
    let opY = 0;
    if(Util.isSP())
    {
      // opY = ($(window).scrollTop() - this.mainvisual.offset().top + 145)/1.5;
    }
    else
    {
      opY = ($(window).scrollTop() - this.mainvisual.offset().top + 80)/1.5;
    }
    if(opY < 0) opY = 0;
    TweenMax.set(this.mainvisualImg, {y:opY});
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	リサイズ処理
	// ---------------------------------------------------------------------------------------------------------

  resize(){

    //画像サイズを画面の幅に合わせる
    this.mainvisualImg.css("width", this.block.width()+"px");

    //コンテナの高さを画像に合わせる
    this.mainvisual.css("height", this.mainvisualImg.height()+"px");
    this.mainvisual.css("overflow", "hidden");
  }


  // ---------------------------------------------------------------------------------------------------------
}

export default WorksDetail;
