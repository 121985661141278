class People
{
  // ---------------------------------------------------------------------------------------------------------
	// --	People
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //表示済みかどうかのフラグ
    this.isShown = false;

    //DOM参照
    this.section = $("#people");
    this.splash = this.section.find("div.splash");
    this.h2 = this.splash.find("h2");

    //自身参照
    let holder = this;
  }

  /**
   * 表示
   */
  show(){
    if(this.isShown) return;
    this.isShown = true;

    TweenMax.to(this.h2, 0.3, {opacity:1, ease:Power0.easeNone});
    TweenMax.to(this.h2, 0.3, {opacity:0, delay:0.6, ease:Power0.easeNone});
    TweenMax.to(this.splash, 0.5, {opacity:0, ease:Power0.easeNone, delay:1.0, onComplete:()=>{
      this.splash.remove();
    }});
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default People;
