let EventEmitter = require("events").EventEmitter;
import * as Util from './util/Util.js';

class Nav extends EventEmitter
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Nav
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    super();

    //DOM参照
    this.nav = $("nav");
    this.burger = $("div.btn-burger");

    //自身参照
    const holder = this;

    //文字演出
    $("nav").find("ul li a").lettering();
    $("nav").find("ul li a >span").each(function(index){
      // console.log("index:" + index);
      // console.log($(this).text());
      var w = $(this).width();
      var h = $(this).height();
      $(this).css("width", w+"px");
      $(this).css("height", h+"px");
      $(this).css("overflow", "hidden");
      let str = $(this).text();
      $(this).empty();
      $(this).append("<span>" + str + "</span>");
      let span = $(this).find(">span");
      //TweenMax.set(span, {y:-h});
    });

    //ボタンアクション
    $("nav").find("ul li a").each(function(index){

      //ホバー
      $(this).hover(()=>{
        $(this).find(">span").each(function(index2){
          var w = $(this).width();
          var h = $(this).height();
          let char = $(this).find(">span");
          TweenMax.killTweensOf(char);
          TweenMax.to(char, 0.15, {y:h, delay:0.02*index2, onComplete:()=>{
            TweenMax.set(char, {y:-h});
            TweenMax.to(char, 0.15, {y:0});
          }});
        });
      }, ()=>{});

      const href = $(this).attr("href");
      if(href == "#contact")
      {
        $(this).click(function(e){
          e.preventDefault();
          TweenMax.to($("html,body"), 1, {"scrollTop":$(document).height(), ease:Power2.easeInOut});
          holder.close();
          holder.emit("CONTACT_SELECTED");
        });
      }

    });

    //バーガーボタンアクション
    this.burger.find("a").click((e)=>{
      e.preventDefault();
      //ナビゲーション閉じる
      if(this.burger.hasClass("close"))
      {
        this.close();
      }
      //ナビゲーション開く
      else
      {
        this.open();
      }
    });

    //初期表示
    this.nav.hide();
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	開く・閉じる
	// ---------------------------------------------------------------------------------------------------------

  /**
   * 開く
   */
  open(){

    //バーガーボタンの表示変更
    this.burger.addClass("close");

    //表示初期化
    $("nav").find("ul li a >span").each(function(index){
      var h = $(this).height();
      let span = $(this).find(">span");
      TweenMax.killTweensOf(span);
      TweenMax.set(span, {y:-h});
    });
    TweenMax.set(this.nav, {opacity:0});
    this.nav.show();
    if(Util.isSP()) this.nav.css("display", "flex");


    //全体をフェードイン
    TweenMax.to(this.nav, 0.3, {opacity:1, ease:Power0.easeNone, onComplete:()=>{
      this.nav.css("pointer-events", "auto");
    }});

    //テキスト表示
    let delay = 0;
    $("nav").find("ul li a").each(function(index){
      $(this).find(">span").each(function(index2){

        var w = $(this).width();
        var h = $(this).height();

        delay = 0.3 + 0.1*index + 0.02*index2;

        let char = $(this).find(">span");
        TweenMax.to(char, 0.2, {y:h, delay:delay, onComplete:()=>{
          TweenMax.set(char, {y:-h});
          TweenMax.to(char, 0.10, {y:0});
        }});
      });
    });
  }


  /**
   * 閉じる
   */
  close(){

    //ボタンアクション停止
    this.nav.css("pointer-events", "none");

    //バーガーボタンの表示変更
    this.burger.removeClass("close");

    //フェードアウト
    TweenMax.killTweensOf(this.nav);
    TweenMax.to(this.nav, 0.1, {opacity:0.0, ease:Power0.easeNone, onComplete:()=>{
      this.nav.hide();
    }});

  }

  // ---------------------------------------------------------------------------------------------------------
}

export default Nav;
