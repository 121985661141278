let EventEmitter = require("events").EventEmitter;
import Cherry from './Cherry.js';
import TitleView from './TitleView.js';
import Nav from '../Nav.js';
import LogoModel from '../data/LogoModel.js';
import * as Config from '../constant/Config.js';
import * as Util from '../util/Util.js';

class LogoView extends EventEmitter
{
  // ---------------------------------------------------------------------------------------------------------
	// --	LogoView
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    super();

    //変数
    this.headerMode = false;
    this.showHeaderFlag = false;
    this.device = "";
    this.logoStatus = 1;
    this.logoColor = "red";
    this.titleColor = "black";

    //Dom
    this.logoGroup = $("#logo");
    this.tag = this.logoGroup.find(".tag");
    this.burgerBtn = $(".btn-burger");
    this.langNav = $("#lang-nav");
    this.base = $("#header-base");

    //Windowサイズを保持
    this.wW = document.documentElement.clientWidth;
    this.wH = document.documentElement.clientHeight;

    //Domサイズ更新
    this.canvasWidth = $("#canvas-logo-container").width();
    this.canvasHeight = $("#canvas-logo-container").height();

    //アプリケーション
		this.pixi = new PIXI.Application(1, 1, { transparent:true, forceCanvas:true});
    this.pixi.ticker.add(this.update.bind(this));

    //VIEW追加
    document.getElementById("canvas-logo-container").appendChild(this.pixi.view);

    //チェリー配置
    this.cherry = new Cherry();
    // this.cherry.readyForSplash();
    this.cherry.x = this.canvasWidth;
    this.pixi.stage.addChild(this.cherry);

    this.cherry_white = new Cherry(0xFFFFFF);
    this.cherry_white.x = this.canvasWidth;
    this.cherry_white.alpha = 0;
    this.pixi.stage.addChild(this.cherry_white);

    this.cherry_black = new Cherry(0x000000);
    this.cherry_black.x = this.canvasWidth;
    this.cherry_black.alpha = 0;
    this.pixi.stage.addChild(this.cherry_black);

    //ボタンアクション
    this.cherry_black.interactive = true;
    this.cherry_black.buttonMode = true;
    this.cherry_black.on("mouseover", ()=>{
      this.cherry.bound();
      this.cherry_white.bound();
      this.cherry_black.bound();
    });
    this.click = this._click.bind(this);
    this.cherry_black.on("mousedown", this.click);
    this.cherry_black.on("touchstart", this.click);

    //タイトル配置
    this.titleContainer = new PIXI.Container();
    this.pixi.stage.addChild(this.titleContainer);
    this.title_white = new TitleView(0xFFFFFF);
    this.title_white.alpha = 0;
    this.titleContainer.addChild(this.title_white);
    this.title_black = new TitleView();
    this.titleContainer.addChild(this.title_black);
  }


  init(){

    //タイトルのピボット
    this.titleContainer.pivot.x = this.title_black.width/2;
    this.titleContainer.pivot.y = 0;

    //タイトルスプラッシュの準備
    //this.title_black.initForSplash();

    //キャンバスサイズ初期化
    this.updateCanvasSize.bind(this)();
    $(window).resize(this.updateCanvasSize.bind(this));
  }

  bounce(){
    this.cherry.bound();
    this.cherry_white.bound();
    this.cherry_black.bound();
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	表示制御
	// ---------------------------------------------------------------------------------------------------------

  /**
   * スプラッシュ再生
   */
  showSplash(){

    //さくらんぼのスプラッシュ
    this.cherry.showSplash();

    //テキストスプラッシュ
    this.title_black.showSplash();

    //タグ
    TweenMax.to(this.tag, 0.3, {opacity:1, ease:Power0.easeNone, delay:0.6});

    //ヘッダーにフィックス
    TweenMax.delayedCall(1.5, this.showTitle.bind(this));
  }

  /**
   * タイトルを表示
   */
  showTitle(delay = 0){

    //SPかどうかのフラグ
    this.isSP = (window.innerWidth <= 768);

    //ロゴのステータス変更
    this.logoStatus = 1;

    //モデル
    const model = Util.GetLogoModel(this.logoStatus);

    //チェリーのボタンアクション停止
    this.cherry_black.interactive = false;

    //タグ非表示
    TweenMax.killTweensOf(this.tag);
    TweenMax.to(this.tag, 0.4, {opacity:0});

    //チェリーアップデート
    this.updateCherry(delay);

    //タイトルの位置とサイズ
    TweenMax.killTweensOf(this.titleContainer.scale);
    TweenMax.to(this.titleContainer.scale, 1.0, {x:model.opTitleS, y:model.opTitleS, ease:Power2.easeInOut, delay:delay});
    TweenMax.killTweensOf(this.titleContainer);
    TweenMax.to(this.titleContainer, 1.0, {y:model.opTitleY, ease:Power2.easeInOut, delay:delay});

    //タイトルの表示
    TweenMax.to(this.titleContainer, 0.4, {alpha:1, delay:1.0 + delay});

    //ロゴの位置変更
    TweenMax.killTweensOf(this.logoGroup);
    TweenMax.to(this.logoGroup, 1.0, {top:"0px", ease:Power2.easeInOut, delay:delay, onComplete:()=>{
      this.cherry_black.interactive = true;
      this.emit("LOGO_FIX");
    }});
  }


  /**
   * タイトルを非表示
   */
  hideTitle(delay = 0){

    //SPかどうかのフラグ
    this.isSP = (window.innerWidth <= 768);

    //ロゴのステータス変更
    this.logoStatus = 2;

    //フラグ
    this.headerMode = true;
    this.showHeaderFlag = true;

    //タイトル非表示
    TweenMax.killTweensOf(this.titleContainer);
    TweenMax.to(this.titleContainer, 0.4, {alpha:0, delay:delay});

    //チェリーアップデート
    this.updateCherry(delay);
  }

  /**
   * チェリーの位置とサイズをアップデート
   */
  updateCherry(delay = 0){

    //モデル
    const model = Util.GetLogoModel(this.logoStatus);

    //チェリーのリサイズ
    TweenMax.killTweensOf(this.cherry.scale);
    TweenMax.to(this.cherry.scale, 1.0, {x:model.opCherryS, y:model.opCherryS, ease:Power2.easeInOut, delay:delay});
    TweenMax.killTweensOf(this.cherry_white.scale);
    TweenMax.to(this.cherry_white.scale, 1.0, {x:model.opCherryS, y:model.opCherryS, ease:Power2.easeInOut, delay:delay});
    TweenMax.killTweensOf(this.cherry_black.scale);
    TweenMax.to(this.cherry_black.scale, 1.0, {x:model.opCherryS, y:model.opCherryS, ease:Power2.easeInOut, delay:delay});

    //チェリーの位置
    TweenMax.killTweensOf(this.cherry);
    TweenMax.to(this.cherry, 1.0, {y:model.opCherryY, ease:Power2.easeInOut, delay:delay});
    TweenMax.killTweensOf(this.cherry_white);
    TweenMax.to(this.cherry_white, 1.0, {y:model.opCherryY, ease:Power2.easeInOut, delay:delay});
    TweenMax.killTweensOf(this.cherry_black);
    TweenMax.to(this.cherry_black, 1.0, {y:model.opCherryY, ease:Power2.easeInOut, delay:delay});
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	ヘッダーの表示制御
	// ---------------------------------------------------------------------------------------------------------

  /**
   * ヘッダー表示
   */
  showHeader(){

    if(!this.headerMode) return;
    if(this.showHeaderFlag) return;
    this.showHeaderFlag = true;

    TweenMax.killTweensOf(this.logoGroup);
    TweenMax.to(this.logoGroup, 0.5, {top:"0px", ease:Power2.easeInOut, onComplete:()=>{
      this.cherry.interactive = true;
    }});

    TweenMax.killTweensOf(this.base);
    TweenMax.to(this.base, 0.5, {y:0, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.langNav);
    TweenMax.to(this.langNav, 0.5, {y:0, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.burgerBtn);
    TweenMax.to(this.burgerBtn, 0.5, {y:0, ease:Power2.easeInOut});
  }


  /**
   * ヘッダー隠す
   */
  hideHeader(){

    if(!this.headerMode) return;
    if(!this.showHeaderFlag) return;
    this.showHeaderFlag = false;

    TweenMax.killTweensOf(this.logoGroup);
    TweenMax.to(this.logoGroup, 0.5, {top:"-80px", ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.base);
    TweenMax.to(this.base, 0.5, {y:-80, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.langNav);
    TweenMax.to(this.langNav, 0.5, {y:-80, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.burgerBtn);
    TweenMax.to(this.burgerBtn, 0.5, {y:-80, ease:Power2.easeInOut});
  }



  // ---------------------------------------------------------------------------------------------------------
	// --	色の変更
	// ---------------------------------------------------------------------------------------------------------

  /**
   * 色を更新
   */
  updateColor(color){
    this.setCherryColor(color.logo);
    this.setTitleColor(color.ui);
  }

  /**
   * チェリーカラー変更
   */
  setCherryColor(col, duration = 0.6){

    if(col == this.logoColor) return;
    this.logoColor = col;

    // TweenMax.killTweensOf(this.cherry);
    // TweenMax.killTweensOf(this.cherry_white);
    // TweenMax.killTweensOf(this.cherry_black);

    if(col == "black")
    {
      TweenMax.to(this.cherry, duration, {alpha:0, ease:Power0.easeNone});
      TweenMax.to(this.cherry_white, duration, {alpha:0, ease:Power0.easeNone});
      TweenMax.to(this.cherry_black, duration, {alpha:1, ease:Power0.easeNone});
    }
    else if(col == "white")
    {
      TweenMax.to(this.cherry, duration, {alpha:0, ease:Power0.easeNone});
      TweenMax.to(this.cherry_white, duration, {alpha:1, ease:Power0.easeNone});
      TweenMax.to(this.cherry_black, duration, {alpha:0, ease:Power0.easeNone});
    }
    else if(col == "red")
    {
      TweenMax.to(this.cherry, duration, {alpha:1, ease:Power0.easeNone});
      TweenMax.to(this.cherry_white, duration, {alpha:0, ease:Power0.easeNone});
      TweenMax.to(this.cherry_black, duration, {alpha:0, ease:Power0.easeNone});
    }
  }

  /**
   * タイトルカラー変更
   */
  setTitleColor(col, duration = 0.6){

    if(col == this.titleColor) return;
    this.titleColor = col;

    // TweenMax.killTweensOf(this.title_white);
    // TweenMax.killTweensOf(this.title_black);

    if(col == "black")
    {
      TweenMax.to(this.title_white, duration, {alpha:0, ease:Power0.easeNone});
      TweenMax.to(this.title_black, duration, {alpha:1, ease:Power0.easeNone});
    }
    else if(col == "white")
    {
      TweenMax.to(this.title_white, duration, {alpha:1, ease:Power0.easeNone});
      TweenMax.to(this.title_black, duration, {alpha:0, ease:Power0.easeNone});
    }
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	更新
	// ---------------------------------------------------------------------------------------------------------

  update(){
    // console.log("update");
    if(this.cherry)
    {
      this.cherry.update();
      this.cherry_white.update();
      this.cherry_black.update();
    }
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	リサイズ
	// ---------------------------------------------------------------------------------------------------------

  //キャンバスサイズをアップデート
	updateCanvasSize(){

    // console.log("===========updateCanvasSize==============");

    //Windowサイズを保持
    let preW = this.wW;
    let preH = this.wH;
    this.wW = document.documentElement.clientWidth;
    this.wH = document.documentElement.clientHeight;

    //デバイスの種類を更新
    let preDevice = this.device;
    this.device = (window.innerWidth <= 768)?"sp":"pc";

    //デバイスの変更があったかどうかのフラグ
    let deviceChangeFlag = (preDevice != this.device);

    //モデル
    const model = Util.GetLogoModel(this.logoStatus);

    //CANVASリサイズ
    if(deviceChangeFlag)
    {
      // console.log("CANVASリサイズ");
      this.canvasWidth = $("#canvas-logo-container").width();
      this.canvasHeight = $("#canvas-logo-container").height();
  		this.pixi.renderer.resize(this.canvasWidth*2, this.canvasHeight*2);
    }

    //縦のリサイズ
    if(deviceChangeFlag || (this.device == "pc" && this.wH != preH))
    {
      // alert("縦のリサイズ");
      // console.log("縦のリサイズ");
      // 中央表示の場合は縦センターに変更
      if(this.logoStatus == 0)
      {
        // console.log("ロゴを縦センターに移動");
        //縦センターに配置
        let wH = document.documentElement.clientHeight;
        let logoH = this.logoGroup.outerHeight();
        this.logoGroup.css("top", Math.floor(wH/2 - logoH/2) + "px");
      }
    }

    //横のリサイズ
    if(deviceChangeFlag)
    {
      //チェリーのサイズと位置
      TweenMax.killTweensOf(this.cherry);
      TweenMax.killTweensOf(this.cherry.scale);
      this.cherry.scale.set(model.opCherryS, model.opCherryS);
      this.cherry.x = this.canvasWidth;
      this.cherry.y = model.opCherryY;

      TweenMax.killTweensOf(this.cherry_white);
      TweenMax.killTweensOf(this.cherry_white.scale);
      this.cherry_white.scale.set(model.opCherryS, model.opCherryS);
      this.cherry_white.x = this.canvasWidth;
      this.cherry_white.y = model.opCherryY;

      TweenMax.killTweensOf(this.cherry_black);
      TweenMax.killTweensOf(this.cherry_black.scale);
      this.cherry_black.scale.set(model.opCherryS, model.opCherryS);
      this.cherry_black.x = this.canvasWidth;
      this.cherry_black.y = model.opCherryY;

      //タイトルのサイズと位置
      TweenMax.killTweensOf(this.titleContainer);
      TweenMax.killTweensOf(this.titleContainer.scale);
      this.titleContainer.scale.set(model.opTitleS, model.opTitleS);
      this.titleContainer.x = this.canvasWidth;
      this.titleContainer.y = model.opTitleY;
    }
	}


  // ---------------------------------------------------------------------------------------------------------
	// --	入力
	// ---------------------------------------------------------------------------------------------------------

  _click(e){
    this.emit("NAV_SELECTED", "#fv");
  }


  // ---------------------------------------------------------------------------------------------------------
}

export default LogoView;
