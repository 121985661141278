let EventEmitter = require("events").EventEmitter;
import * as Util from './util/Util.js';

class FirstView extends EventEmitter
{
  // ---------------------------------------------------------------------------------------------------------
	// --	FirstView
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    super();

    //DOM参照
    this.section = $("#fv");
    this.wrapper = this.section.find(".mv-wrapper");
    this.list = this.section.find(".mv-list");
    this.paging = this.section.find(".mv-paging");

    //ロックフラグ
    this.isLock = false;

    //オートスライド用のタイマー
    this.autoSlideTimer;

    //スワイプ用変数
    this.isDrag = false;
    this.prevTouchX = 0;
    this.dragDistance = 0;

    //リストの数
    this.listNum = this.list.find(".mv-list-item").length;
    this.listIndex = 0;

    //背景画像セット
    const holder = this;
    this.list.find(".mv-list-item").each(function(index){

      const li = $(this);
      const bgimg = (Util.isSP() && li.data("background-img-sp"))?li.data("background-img-sp"):li.data("background-img");
      li.find(">a").css("background-image", "url("+bgimg+")");

      //初期非表示
      TweenMax.set(li, {alpha:0});
    });

    //スワイプ対応
    this.list.on('touchstart', this.onTouchStart.bind(this));
    this.list.on('touchmove', this.onTouchMove.bind(this));
    this.list.on('touchend', this.onTouchEnd.bind(this));

    //ページングUI初期化
    for(let i=0; i<this.listNum; i++)
    {
      this.paging.append('<li class="mv-paging-item"><a href="#"></a></li>');
    }
    this.paging.find("a").each(function(index){
      $(this).click(function(e){
        holder.changePage(index);
        return false;
      });
    });

    this.updatePaging();
  }

  /**
   * スタート
   */
  start(){
    this.changePage(0);
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	カルーセル関連
	// ---------------------------------------------------------------------------------------------------------

  /**
   * オートスライドリセット
   */
  resetAutoSlide(delay = 6000){

    //オートスライド一旦停止
    if(this.autoSlideTimer)
    {
      clearTimeout(this.autoSlideTimer);
      this.autoSlideTimer = null;
    }

    //次のオートスライド
    this.autoSlideTimer = setTimeout(()=>{
      this.autoSlide();
    }, delay);
  }

  /**
   * オートスライド
   */
  autoSlide(){
    this.changePage(++this.listIndex);
  }

  /**
   * ページ変更
   */
  changePage(index, duration = 0.6){

    //ロック
    if(this.isLock) return;
    this.isLock = true;

    //インデックス保持
    this.listIndex = index;
    if(this.listIndex >= this.listNum)
    {
      this.listIndex = 0;
    }
    else if(this.listIndex < 0)
    {
      this.listIndex = this.listNum - 1;
    }

    //イベント発行
    this.emit("ON_SLIDE_CHANGE");

    //ページングアップデート
    this.updatePaging();

    //クロスフェード
    const holder = this;
    this.list.find(".mv-list-item").each(function(index){
      const li = $(this);
      const opA = (index == holder.listIndex)?1.0:0.0;
      TweenMax.killTweensOf(li);
      TweenMax.to(li, duration, {alpha:opA, ease:Power0.easeNone, onComplete:holder.fix.bind(holder)});
    });
  }

  /**
   * スライドフィックス
   */
  fix(){

    //ロック解除
    this.isLock = false;

    //アクティブなスライドを最前面に
    const holder = this;
    this.list.find(".mv-list-item").each(function(index){
      const li = $(this);
      const zIndex = (index == holder.listIndex)?1:0;
      li.css("z-index", zIndex);
      li.css("width", "100%");
    });

    //イベント発行
    this.emit("ON_SLIDE_FIX");

    //オートスライド
    this.resetAutoSlide();
  }

  /**
   * ページングをアップデート
   */
  updatePaging(){

    //色の更新
    this.paging.removeClass("white");
    if(this.getCurrentColor().ui == "white") this.paging.addClass("white");

    //アクティブ状態の更新
    this.paging.find("a").removeClass("selected");
    this.paging.find("a").eq(this.listIndex).addClass("selected");
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	スワイプ関連
	// ---------------------------------------------------------------------------------------------------------

  onTouchStart(e){

    //ロック中はドラッグさせない
    if(this.isLock) return true;
    if(this.isDrag) return true;

    //ドラッグ中フラグを立てる
    this.isDrag = true;

    //オートスライド一旦停止
    if(this.autoSlideTimer)
    {
      clearTimeout(this.autoSlideTimer);
      this.autoSlideTimer = null;
    }

    //ドラッグ距離リセット
    this.dragDistance = 0;

    //タッチ位置保存
    this.prevTouchX = e.originalEvent.touches[0].clientX;
  }

  onTouchMove(e){

    //フラグが立っていなければ処理なし
    if(!this.isDrag) return true;

    //ドラッグ距離
    this.dragDistance += e.originalEvent.touches[0].clientX - this.prevTouchX;

    // //スワイプ
    // const swipeRate = Math.abs(this.dragDistance)/$(window).outerWidth();
    //
    // //リスト参照
    // const preLi = this.getCurrentList(-1);
    // const currentLi = this.getCurrentList(0);
    // const nextLi = this.getCurrentList(1);
    //
    // //マスク移動
    // const img = currentLi.find(">a");
    // TweenMax.set(currentLi, {width:(100-swipeRate*100)+"%"});
    //
    // //左にスワイプ
    // if(this.dragDistance < 0)
    // {
    //   //固定
    //   currentLi.css({
    //     right:"auto",
    //     left:0
    //   });
    //   img.css({
    //     right:"auto",
    //     left:0
    //   });
    //
    //   //次のスライドを表示
    //   TweenMax.set(preLi, {alpha:0});
    //   TweenMax.set(nextLi, {alpha:1});
    // }
    // //右にスワイプ
    // else if(this.dragDistance > 0)
    // {
    //   //固定
    //   currentLi.css({
    //     left:"auto",
    //     right:0
    //   });
    //   img.css({
    //     left:"auto",
    //     right:0
    //   });
    //
    //   //次のスライドを表示
    //   TweenMax.set(preLi, {alpha:1});
    //   TweenMax.set(nextLi, {alpha:0});
    // }

    //タッチ位置保存
    this.prevTouchX = e.originalEvent.touches[0].clientX;
  }

  onTouchEnd(e){

    //フラグが立っていなければ処理なし
    if(!this.isDrag) return true;
    this.isDrag = false;

    //リスト参照
    const currentLi = this.getCurrentList(0);

    //移動距離によってページスクロール
    if(this.dragDistance > 100)
    {
      this.changePage(--this.listIndex);
      return false;
    }
    else if(this.dragDistance < -100)
    {
      this.changePage(++this.listIndex);
      return false;
    }
    else
    {
      this.changePage(this.listIndex);
    }
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	現在のページの色の設定を取得
	// ---------------------------------------------------------------------------------------------------------

  getCurrentColor(){
    const currentLi = this.list.find(".mv-list-item").eq(this.listIndex);
    const uiColor = currentLi.data("ui-color");
    return Util.getUIColor(uiColor);
  }

  // ---------------------------------------------------------------------------------------------------------
	// --	リスト取得
	// ---------------------------------------------------------------------------------------------------------

  getCurrentList(index = 0)
  {
    let targetIndex = this.listIndex + index;
    if(targetIndex < 0)
    {
      targetIndex = this.listNum - 1;
    }
    else if(targetIndex >= this.listNum)
    {
      targetIndex = 0;
    }

    return this.list.find(".mv-list-item").eq(targetIndex);
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	RESIZE
	// ---------------------------------------------------------------------------------------------------------

  // onResize(){
  //
  //   //リストの位置を再配置
  //   this.fixSlidePos();
  //
  //   //オートスライド
  //   this.resetAutoSlide();
  // }

  // ---------------------------------------------------------------------------------------------------------
}

export default FirstView;
