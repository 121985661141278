import * as Config from '../constant/Config.js';


class Map
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Map
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //Domサイズ更新
    this.canvasWidth = $("#canvas-map-container").width();
    this.canvasHeight = $("#canvas-map-container").height();

    //アプリケーション
		this.pixi = new PIXI.Application(this.canvasWidth*2, this.canvasHeight*2, { transparent:true, forceCanvas:true});

    //VIEW追加
    document.getElementById("canvas-map-container").appendChild(this.pixi.view);

    //マップコンテナ
    this.mapContainer = new PIXI.Container();
    this.pixi.stage.addChild(this.mapContainer);

    //マップ
    const map = new PIXI.Sprite.fromImage("/assets/img/whoweare/map.png");
    this.mapContainer.addChild(map);

    //さくらんぼ
    this.cherry = new PIXI.Sprite.fromImage("/assets/img/whoweare/map_cherry.png");
    this.cherry.anchor.set(0.5, 0.5);
    this.cherry.x = 1029;
    this.cherry.y = 594;
    this.mapContainer.addChild(this.cherry);
    TweenMax.to(this.cherry.scale, 0.8, {x:0.8, y:0.8, ease:Power1.easeIn, repeat:-1, yoyo:true});

    //ロード済みフラグ
    this.isLoaded = false;

    //ロード済み
    if(map.width > 1 && map.height > 1)
    {
      this.mapLoaded();
    }
    //未ロード->ロード完了を待つ
    else
    {
      map.texture.baseTexture.once('loaded', ()=>{
        this.mapLoaded();
      });
    }

    //キャンバスサイズ初期化
    this.updateCanvasSize.bind(this)();
    $(window).resize(this.updateCanvasSize.bind(this));

  }


  // ---------------------------------------------------------------------------------------------------------
	// --	リサイズ
	// ---------------------------------------------------------------------------------------------------------

  //キャンバスサイズをアップデート
	updateCanvasSize(){

    //canvasのサイズを変更
    const preCanvasWidth = this.canvasWidth;
    const preCanvasHeight = this.canvasHeight;
    this.canvasWidth = $("#canvas-map-container").width();
    this.canvasHeight = $("#canvas-map-container").height();

    //地図コンテナリサイズ
    this.updateMapSize();

    //レンダラーリサイズ
    this.pixi.renderer.resize(this.canvasWidth*2, this.canvasHeight*2);
	}


  // ---------------------------------------------------------------------------------------------------------
	// --	マップ
	// ---------------------------------------------------------------------------------------------------------

  mapLoaded(){

    //フラグを立てる
    this.isLoaded = true;

    //マップリサイズ
    this.updateCanvasSize();
  }


  updateMapSize(){

    if(!this.isLoaded) return;

    //コンテナリサイズ
    this.mapContainer.width = this.canvasWidth*2;
    this.mapContainer.height = this.canvasHeight*2;
  }


  // ---------------------------------------------------------------------------------------------------------
}

export default Map;
