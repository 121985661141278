import * as Util from './util/Util.js';

class YearList
{
  // ---------------------------------------------------------------------------------------------------------
	// --	YearList
	// ---------------------------------------------------------------------------------------------------------

  constructor(){}

  init(){

    //リサイズをハンドリング
    $(window).resize(this.resize.bind(this));
    this.resize();
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	リサイズ処理
	// ---------------------------------------------------------------------------------------------------------

  resize(){

    const before = $('.year-list-item--before');
    const now = $('.year-list-item--now');
    now.css('width', before.width() + 'px');
  }


  // ---------------------------------------------------------------------------------------------------------
}

export default YearList;
