import * as Util from './util/Util.js';


class News
{
  // ---------------------------------------------------------------------------------------------------------
	// --	News
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //DOM参照
    this.list = $("ul.news-list");

    //変数
    this.isDragging = false;
    this.startTouchX = 0;
    this.listX = 0;
    this.opX = 0;
    this.startListX = 0;
    this.accell = 0;

    //タッチハンドリング
    this.list.on("touchstart", this.onPress.bind(this));
    this.list.on("touchmove", this.onDrag.bind(this));
  	this.list.on("touchend", this.onRelease.bind(this));

    //デバイス初期
    // this.device = (Util.isSP())?"sp":"pc";
    this.device = null;

    //リサイズハンドリング
    $(window).resize(this.onResize.bind(this));
  	this.onResize();
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	ドラッグ
	// ---------------------------------------------------------------------------------------------------------

  onPress(e){

    if(!Util.isSP()) return;

    //フラグを立てる
    this.isDragging = true;

    //タッチ位置保存
    this.prevTouchX = e.originalEvent.changedTouches[0].pageX;

    //リストのスタート位置
    this.accell = 0;
  }

  onDrag(e){

    if(!Util.isSP()) return;
    if(!this.isDragging) return;

    //最新のタッチ位置
    const currentX = e.originalEvent.changedTouches[0].pageX;

    //加速度を計算
    this.accell = (currentX - this.prevTouchX);

    //タッチ位置保存
    this.prevTouchX = currentX;
  }

  onRelease(e){

    //フラグをもどす
    this.isDragging = false;
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	ループ
	// ---------------------------------------------------------------------------------------------------------

  loop(){
    if(!Util.isSP()) return;

    //制限
    let maxX = 0;
    let minX = -(this.list.outerWidth() - $(window).width());

    if(!this.isDragging)
    {
      this.accell *= 0.95;
    }

    // console.log("this.accell:" + this.accell);

    if(Math.abs(this.accell) > 0.1)
    {
      this.opX += this.accell;
      if(this.opX > maxX) this.opX = maxX;
      if(this.opX < minX) this.opX = minX;
      // console.log("this.opX:" + this.opX);
    }

    this.listX += (this.opX - this.listX)/5;
    TweenMax.set(this.list, {x:this.listX});
  }

  // ---------------------------------------------------------------------------------------------------------
	// --	リサイズ
	// ---------------------------------------------------------------------------------------------------------

  onResize(){

    const newDevice = (Util.isSP())?"sp":"pc";

    if(this.device == newDevice) return;
    this.device = newDevice;

    //位置初期化
    TweenMax.set(this.list, {x:0});

    //フラグをもどす
    this.isDragging = false;

    //リスト初期位置変更
    this.prevTouchX = 0;
    this.accell = 0;
    this.listX = 0;
    this.opX = 0;

    //リストの幅
    if(this.device == "sp")
    {
      let len = this.list.find("li").length;
      let listWidth = 30 + 250*len + 15*(len-1);
      let wW = $(window).width();
      if(listWidth < wW) listWidth = wW;
      this.list.css("width", listWidth + "px");
    }
    else
    {
      this.list.css("width", "100%");
    }
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default News;
