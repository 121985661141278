
class TitleView extends PIXI.Container
{
  // ---------------------------------------------------------------------------------------------------------
	// --	LogoView
	// ---------------------------------------------------------------------------------------------------------

  constructor(color = 0x000000){

    super();

    //コンテナ
    this.container = new PIXI.Container();
    this.addChild(this.container);

    //文字を配置
    for(let i=0; i<6; i++)
    {
      var char = new PIXI.Graphics();
      char.beginFill(color);

      switch (i) {
        case 0:
        char.moveTo(69.8,69.7);
        char.bezierCurveTo(67.6,72.7,64.6,75.2,61,77.10000000000001);
        char.bezierCurveTo(57.4,79.00000000000001,53.2,79.9,48.5,79.9);
        char.bezierCurveTo(44,79.9,39.8,79.10000000000001,36.2,77.5);
        char.bezierCurveTo(32.6,75.9,29.400000000000002,73.7,26.800000000000004,70.9);
        char.bezierCurveTo(24.200000000000006,68.10000000000001,22.200000000000003,64.60000000000001,20.800000000000004,60.60000000000001);
        char.bezierCurveTo(19.400000000000006,56.60000000000001,18.700000000000003,52.20000000000001,18.700000000000003,47.400000000000006);
        char.bezierCurveTo(18.700000000000003,42.800000000000004,19.400000000000002,38.60000000000001,20.900000000000002,34.7);
        char.bezierCurveTo(22.3,30.800000000000004,24.400000000000002,27.400000000000002,27.1,24.500000000000004);
        char.bezierCurveTo(29.8,21.600000000000005,33,19.300000000000004,36.7,17.700000000000003);
        char.bezierCurveTo(40.400000000000006,16.1,44.6,15.200000000000003,49.1,15.200000000000003);
        char.bezierCurveTo(52.6,15.200000000000003,56.300000000000004,15.900000000000002,60,17.400000000000002);
        char.bezierCurveTo(63.7,18.8,66.7,21.1,69,24.200000000000003);
        char.lineTo(69.3,24.6);
        char.lineTo(82.3,14.3);
        char.lineTo(82,13.9);
        char.bezierCurveTo(80.2,11.5,78,9.4,75.4,7.7);
        char.bezierCurveTo(72.9,6,70.10000000000001,4.6,67.2,3.4000000000000004);
        char.bezierCurveTo(64.3,2.3000000000000003,61.300000000000004,1.4000000000000004,58.1,0.8000000000000003);
        char.bezierCurveTo(55,0.3,52,0,49.1,0);
        char.bezierCurveTo(42.2,0,35.6,1.1,29.700000000000003,3.3);
        char.bezierCurveTo(23.800000000000004,5.5,18.500000000000004,8.7,14.200000000000003,12.8);
        char.bezierCurveTo(9.800000000000002,16.9,6.3000000000000025,21.9,3.8000000000000025,27.700000000000003);
        char.bezierCurveTo(1.3,33.5,0,40.1,0,47.3);
        char.bezierCurveTo(0,54.3,1.2,60.9,3.6,66.69999999999999);
        char.bezierCurveTo(6,72.49999999999999,9.3,77.6,13.6,81.79999999999998);
        char.bezierCurveTo(17.8,85.99999999999999,23,89.29999999999998,28.9,91.69999999999999);
        char.bezierCurveTo(34.8,93.99999999999999,41.4,95.19999999999999,48.4,95.19999999999999);
        char.bezierCurveTo(56.1,95.19999999999999,63,93.79999999999998,68.7,90.99999999999999);
        char.bezierCurveTo(74.5,88.19999999999999,79.4,84.29999999999998,83.4,79.29999999999998);
        char.lineTo(83.7,78.89999999999998);
        char.lineTo(70,69.29999999999998);
        char.lineTo(69.8,69.7);
          break;
        case 1:
        char.moveTo(165.3,38.5);
        char.lineTo(124.5,38.5);
        char.lineTo(124.5,2.3);
        char.lineTo(107.5,2.3);
        char.lineTo(107.5,92.9);
        char.lineTo(124.5,92.9);
        char.lineTo(124.5,53.2);
        char.lineTo(165.3,53.2);
        char.lineTo(165.3,92.9);
        char.lineTo(182.3,92.9);
        char.lineTo(182.3,2.3);
        char.lineTo(165.3,2.3);
        char.lineTo(165.3,38.5);
          break;
        case 2:
        char.moveTo(231.9,53.4);
        char.lineTo(273,53.4);
        char.lineTo(273,39);
        char.lineTo(231.9,39);
        char.lineTo(231.9,17.2);
        char.lineTo(275.3,17.2);
        char.lineTo(275.3,2.3);
        char.lineTo(214.9,2.3);
        char.lineTo(214.9,92.9);
        char.lineTo(277.6,92.9);
        char.lineTo(277.6,77.7);
        char.lineTo(231.9,77.7);
        char.lineTo(231.9,53.4);
          break;
        case 3:
        char.moveTo(367.5,44.7);
        char.bezierCurveTo(371.2,40.5,373.1,35.1,373.1,28.500000000000004);
        char.bezierCurveTo(373.1,23.700000000000003,372.1,19.6,370.20000000000005,16.200000000000003);
        char.bezierCurveTo(368.30000000000007,12.900000000000002,365.80000000000007,10.100000000000003,362.6,8.000000000000004);
        char.bezierCurveTo(359.5,6.0000000000000036,355.90000000000003,4.5000000000000036,351.90000000000003,3.600000000000003);
        char.bezierCurveTo(347.90000000000003,2.7000000000000033,343.70000000000005,2.3000000000000034,339.50000000000006,2.3000000000000034);
        char.lineTo(307.90000000000003,2.3000000000000034);
        char.lineTo(307.90000000000003,92.89999999999999);
        char.lineTo(324.90000000000003,92.89999999999999);
        char.lineTo(324.90000000000003,54.89999999999999);
        char.lineTo(335.70000000000005,54.89999999999999);
        char.lineTo(356.40000000000003,92.89999999999999);
        char.lineTo(376.90000000000003,92.89999999999999);
        char.lineTo(352.90000000000003,52.99999999999999);
        char.bezierCurveTo(359,51.5,363.9,48.7,367.5,44.7);
        char.moveTo(356.1,28.4);
        char.bezierCurveTo(356.1,31,355.5,33.1,354.40000000000003,34.699999999999996);
        char.bezierCurveTo(353.3,36.3,351.8,37.599999999999994,350.1,38.49999999999999);
        char.bezierCurveTo(348.3,39.39999999999999,346.3,39.99999999999999,344.1,40.29999999999999);
        char.bezierCurveTo(341.90000000000003,40.59999999999999,339.6,40.79999999999999,337.3,40.79999999999999);
        char.lineTo(324.8,40.79999999999999);
        char.lineTo(324.8,16.7);
        char.lineTo(338.7,16.7);
        char.bezierCurveTo(340.7,16.7,342.8,16.9,344.9,17.2);
        char.bezierCurveTo(347,17.5,348.9,18.099999999999998,350.5,19);
        char.bezierCurveTo(352.1,19.9,353.5,21.1,354.5,22.6);
        char.bezierCurveTo(355.6,24.1,356.1,26,356.1,28.4);
        char.addHole();
          break;
        case 4:
        char.moveTo(462.2,44.7);
        char.bezierCurveTo(465.9,40.5,467.8,35.1,467.8,28.500000000000004);
        char.bezierCurveTo(467.8,23.700000000000003,466.8,19.6,464.90000000000003,16.200000000000003);
        char.bezierCurveTo(463.00000000000006,12.900000000000002,460.50000000000006,10.100000000000003,457.3,8.000000000000004);
        char.bezierCurveTo(454.2,6.0000000000000036,450.6,4.5000000000000036,446.6,3.600000000000003);
        char.bezierCurveTo(442.6,2.7000000000000033,438.40000000000003,2.3000000000000034,434.20000000000005,2.3000000000000034);
        char.lineTo(402.6,2.3000000000000034);
        char.lineTo(402.6,92.89999999999999);
        char.lineTo(419.6,92.89999999999999);
        char.lineTo(419.6,54.89999999999999);
        char.lineTo(430.40000000000003,54.89999999999999);
        char.lineTo(451.1,92.89999999999999);
        char.lineTo(471.6,92.89999999999999);
        char.lineTo(447.6,52.99999999999999);
        char.bezierCurveTo(453.7,51.5,458.6,48.7,462.2,44.7);
        char.moveTo(450.9,28.4);
        char.bezierCurveTo(450.9,31,450.29999999999995,33.1,449.2,34.699999999999996);
        char.bezierCurveTo(448.09999999999997,36.3,446.59999999999997,37.599999999999994,444.9,38.49999999999999);
        char.bezierCurveTo(443.09999999999997,39.39999999999999,441.09999999999997,39.99999999999999,438.9,40.29999999999999);
        char.bezierCurveTo(436.7,40.59999999999999,434.4,40.79999999999999,432.09999999999997,40.79999999999999);
        char.lineTo(419.59999999999997,40.79999999999999);
        char.lineTo(419.59999999999997,16.7);
        char.lineTo(433.49999999999994,16.7);
        char.bezierCurveTo(435.49999999999994,16.7,437.59999999999997,16.9,439.69999999999993,17.2);
        char.bezierCurveTo(441.79999999999995,17.5,443.69999999999993,18.099999999999998,445.29999999999995,19);
        char.bezierCurveTo(446.9,19.9,448.29999999999995,21.1,449.29999999999995,22.6);
        char.bezierCurveTo(450.3,24.1,450.9,26,450.9,28.4);
        char.addHole();
          break;
        case 5:
        char.moveTo(549.1,2.3);
        char.lineTo(526.7,39.5);
        char.lineTo(504.4,2.3);
        char.lineTo(483.7,2.3);
        char.lineTo(517.8,53.7);
        char.lineTo(517.8,92.9);
        char.lineTo(534.8,92.9);
        char.lineTo(534.8,53.7);
        char.lineTo(568.9,2.3);
        char.lineTo(549.1,2.3);
          break;
        default:
      }

      char.endFill();
      this.container.addChild(char);
    }
  }


  /**
   * スプラッシュの準備
   */
  initForSplash(){

    let charMask = new PIXI.Graphics();
    charMask.beginFill(0x000000);
    charMask.drawRect(0, 0, this.width, this.height);
    charMask.endFill();
    this.addChild(charMask);
    this.container.mask = charMask;

    let len = this.container.children.length;
    for(let i=0; i<len; i++){
      let char = this.container.getChildAt(i);
      char.y = -this.height - 10;
    }
  }


  /**
   * スプラッシュの表示
   */
  showSplash(){
    let len = this.container.children.length;
    for(let i=0; i<len; i++){
      let char = this.container.getChildAt(i);
      TweenMax.to(char, 0.3, {y:0, ease:Power2.easeOut, delay:0.3 + 0.06*i});
    }
  }
  
  // ---------------------------------------------------------------------------------------------------------
}

export default TitleView;
