// ロゴのサイズ
export const SIZE_NORMAL_PC = 0.88;
export const SIZE_NORMAL_SP = 0.5;
export const SIZE_HEADER_PC = 0.28;
export const SIZE_HEADER_SP = 0.24;
export const SIZE_SMALL_PC = 0.4;
export const SIZE_SMALL_SP = 0.28;

//ロゴの位置
export const POS_NORMAL_PC = 60;
export const POS_NORMAL_SP = 40;
export const POS_HEADER_PC = 30;
export const POS_HEADER_SP = 40;
export const POS_SMALL_PC = 50;
export const POS_SMALL_SP = 16;

//タイトルのサイズ
export const TITLE_SIZE_NORMAL_PC = 284/284;
export const TITLE_SIZE_NORMAL_SP = 164/284;
export const TITLE_SIZE_HEADER_PC = 85/284;
export const TITLE_SIZE_HEADER_SP = 76/284;

//タイトルの位置
export const TITLE_POS_NORMAL_PC = 260;
export const TITLE_POS_NORMAL_SP = 152;
export const TITLE_POS_HEADER_PC = 90;
export const TITLE_POS_HEADER_SP = 94;
