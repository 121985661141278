class Footer
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Footer
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //DOM
    this.footer = $("footer");
    this.spacer = $(".footer-spacer");
    this.group = this.footer.find(".group");

    //INVIEWをチェック
    this.onScroll = this._onScroll.bind(this);
    $(window).scroll(this.onScroll);
    this.onScroll();
  }

  _onScroll(){

    //Windowの高さ
    let wHeight = document.documentElement.clientHeight;
    let spacerH = this.spacer.height();

    let minY = this.spacer.offset().top - wHeight + spacerH/2;
    let maxY = minY + spacerH/2 - 100;
    let scrollTop = $(window).scrollTop();
    let rate = (scrollTop-minY)/(maxY-minY);
    if(rate < 0) rate = 0;
    if(rate > 1) rate = 1;

    TweenMax.set(this.group, {opacity:rate, y:(1-rate)*60});
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default Footer;
