class Statement
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Statement
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //表示済みかどうかのフラグ
    this.isShown = false;

    //DOM参照
    this.section = $("#statement");
    this.wrapper = this.section.find(".statement-wrapper");
    this.title = this.section.find("p.msg-title");
    this.messages = this.section.find("p.msg-body");
    this.en = this.section.find("p.msg-en");
    this.viewmore = this.section.find("div.viewmore-container");
    this.splash = this.section.find("div.splash");
    this.h2 = this.splash.find("h2");

    //自身参照
    let holder = this;
  }


  /**
   * 表示
   */
  show(){
    if(this.isShown) return;
    this.isShown = true;

    this.showSplash();
  }

  showSplash()
  {
    TweenMax.to(this.h2, 0.3, {opacity:1, ease:Power0.easeNone});
    TweenMax.to(this.splash, 0.3, {opacity:0, ease:Power0.easeNone, delay:0.6, onComplete:()=>{
      this.showStatement();
    }});

    TweenMax.set(this.title, {opacity:0});
    TweenMax.set(this.messages, {opacity:0});
    TweenMax.set(this.en, {opacity:0});
    TweenMax.set(this.viewmore, {opacity:0});
    this.wrapper.css('opacity', 1.0);
  }

  showStatement()
  {
    //スプラッシュ削除
    this.splash.remove();

    //フェードイン
    TweenMax.to(this.title, 0.6, {opacity:1, ease:Power0.easeNone, delay:0.2});
    TweenMax.to(this.messages, 0.6, {opacity:1, ease:Power0.easeNone, delay:0.2});
    TweenMax.to(this.en, 0.6, {opacity:1, ease:Power0.easeNone, delay:0.2});
    TweenMax.to(this.viewmore, 0.6, {opacity:1, ease:Power0.easeNone, delay:0.2});
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default Statement;
