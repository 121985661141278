import LogoModel from '../data/LogoModel.js';

export function isSP()
{
  return (window.innerWidth <= 768);
}

export function GetLogoModel(status)
{
  return new LogoModel(status);
}

export function getUIColor(uc)
{
  let logoColor = "";
  let uiColor = "";

  if(uc == "" || isSP())
  {
    logoColor = "red";
    uiColor = "black";
  }
  else
  {
    let arr = uc.split("-");
    switch (arr[0]) {
      case "r":
        logoColor = "red";
        break;
      case "w":
        logoColor = "white";
        break;
      case "b":
        logoColor = "black";
        break;
      default:
        logoColor = "red";
    }
    switch (arr[1]) {
      case "w":
        uiColor = "white";
        break;
      case "b":
        uiColor = "black";
        break;
      default:
        uiColor = "black";
    }
  }

  return {logo:logoColor, ui:uiColor};
}
