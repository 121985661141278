let EventEmitter = require("events").EventEmitter;
import Cherry from './Cherry.js';
import * as Config from '../constant/Config.js';


class SmallIconView extends EventEmitter
{
  // ---------------------------------------------------------------------------------------------------------
	// --	SmallIconView
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    super();

    //変数
    this.showHeaderFlag = true;
    this.device = "";

    //Dom
    this.icon = $("#cherry-icon");
    this.burgerBtn = $(".btn-burger");
    this.langNav = $("#lang-nav");
    this.base = $("#header-base");

    //Windowサイズを保持
    this.wW = document.documentElement.clientWidth;
    this.wH = document.documentElement.clientHeight;

    //Domサイズ更新
    this.canvasWidth = $("#canvas-logo-container").width();
    this.canvasHeight = $("#canvas-logo-container").height();

    //アプリケーション
		this.pixi = new PIXI.Application(this.canvasWidth*2, this.canvasHeight*2, { transparent:true, forceCanvas:true});
    this.pixi.ticker.add(this.update.bind(this));

    //VIEW追加
    document.getElementById("canvas-logo-container").appendChild(this.pixi.view);

    //チェリー配置
    this.cherry = new Cherry();
    this.cherry.readyForSplash();
    this.pixi.stage.addChild(this.cherry);

    //ボタンアクション
    this.cherry.interactive = true;
    this.cherry.buttonMode = true;
    this.cherry.on("mouseover", ()=>{
      this.cherry.bound();
    });
    this.click = this._click.bind(this);
    this.cherry.on("mousedown", this.click);
    this.cherry.on("touchstart", this.click);
  }

  init(){
    //キャンバスサイズ初期化
    this.updateCanvasSize.bind(this)();
    $(window).resize(this.updateCanvasSize.bind(this));
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	表示制御
	// ---------------------------------------------------------------------------------------------------------

  /**
   * スプラッシュ再生
   */
  showSplash(){
    this.cherry.showSplash();
  }

  /**
   * ヘッダー表示
   */
  showHeader(){
    if(this.showHeaderFlag) return;
    this.showHeaderFlag = true;

    TweenMax.killTweensOf(this.icon);
    TweenMax.to(this.icon, 0.5, {top:"0px", ease:Power2.easeInOut, onComplete:()=>{
      this.cherry.interactive = true;
    }});

    TweenMax.killTweensOf(this.base);
    TweenMax.to(this.base, 0.5, {y:0, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.langNav);
    TweenMax.to(this.langNav, 0.5, {y:0, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.burgerBtn);
    TweenMax.to(this.burgerBtn, 0.5, {y:0, ease:Power2.easeInOut});
  }


  /**
   * ヘッダー隠す
   */
  hideHeader(){
    if(!this.showHeaderFlag) return;
    this.showHeaderFlag = false;

    TweenMax.killTweensOf(this.icon);
    TweenMax.to(this.icon, 0.5, {top:"-80px", ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.base);
    TweenMax.to(this.base, 0.5, {y:-80, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.langNav);
    TweenMax.to(this.langNav, 0.5, {y:-80, ease:Power2.easeInOut});

    TweenMax.killTweensOf(this.burgerBtn);
    TweenMax.to(this.burgerBtn, 0.5, {y:-80, ease:Power2.easeInOut});
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	更新
	// ---------------------------------------------------------------------------------------------------------

  update(){
    // console.log("update");
    if(this.cherry) this.cherry.update();
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	リサイズ
	// ---------------------------------------------------------------------------------------------------------

  //キャンバスサイズをアップデート
	updateCanvasSize(){

    // console.log("===========updateCanvasSize==============");

    //Windowサイズを保持
    let preW = this.wW;
    let preH = this.wH;
    this.wW = document.documentElement.clientWidth;
    this.wH = document.documentElement.clientHeight;

    //デバイスの種類を更新
    let preDevice = this.device;
    this.device = (window.innerWidth <= 768)?"sp":"pc";

    //デバイスの変更があったかどうかのフラグ
    let deviceChangeFlag = (preDevice != this.device);

    //CANVASリサイズ
    if(deviceChangeFlag)
    {
      // console.log("CANVASリサイズ");
      this.canvasWidth = $("#canvas-logo-container").width();
      this.canvasHeight = $("#canvas-logo-container").height();
  		this.pixi.renderer.resize(this.canvasWidth*2, this.canvasHeight*2);
    }

    //横のリサイズ
    if(deviceChangeFlag)
    {
      // console.log("レイアウト");

      //SP
      if(this.device == "sp")
      {
        this.cherry.scale.set(Config.SIZE_SMALL_SP, Config.SIZE_SMALL_SP);
        this.cherry.x = this.canvasWidth;
        this.cherry.y = Config.POS_SMALL_SP;
      }
      //PC
      else
      {
        this.cherry.scale.set(Config.SIZE_SMALL_PC, Config.SIZE_SMALL_PC);
        this.cherry.x = this.canvasWidth;
        this.cherry.y = Config.POS_SMALL_PC;
      }
    }
	}


  // ---------------------------------------------------------------------------------------------------------
	// --	入力
	// ---------------------------------------------------------------------------------------------------------

  _click(e){
    // console.log(location.href);
    // console.log(location.href.indexOf("/en/"));
    // return;
    const href = (location.href.indexOf("/en/")>=0)?"/en/":"/";
    this.emit("ICON_SELECT", {href:href, pageX:e.data.originalEvent.pageX, pageY:e.data.originalEvent.pageY-$(window).scrollTop(), top:true});
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default SmallIconView;
