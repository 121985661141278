
class Fader
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Fader
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //DOM参照
    this.fader = $("#fader");
    this.circle = this.fader.find(".fader__circle");
    this.white = this.fader.find(".fader__white");

    //ステータス
    this.status = 0;

    //キャンバスサイズ
    $(window).resize(this.onResize.bind(this));
    this.onResize.bind(this)();
  }

  // ---------------------------------------------------------------------------------------------------------
	// --	ENTER / LEAVE
	// ---------------------------------------------------------------------------------------------------------

  /**
   * ページに入る
   */
  enter(){
    TweenMax.to(this.circle, 0.3, {scaleX:0, scaleY:0, onComplete:()=>{
      this.fader.hide();
    }});
  }

  /**
   * ページから離れる
   */
  leave(data){

    // console.log(data);

    //TOPへの遷移
    if(data.top)
    {
      //画面サイズ
      const w = this.fader.width();
      const h = this.fader.height();

      //中心点をずらす
      const posX = -w/2 + data.pageX;
      const posY = -h/2 + data.pageY;
      TweenMax.set(this.circle, {x:posX, y:posY});

      //拡大アニメーション
      const max = Math.max(
        Math.max(Math.sqrt(Math.pow(0-data.pageX,2)+Math.pow(0-data.pageY,2)), Math.sqrt(Math.pow(w-data.pageX,2)+Math.pow(h-data.pageY,2))),
        Math.max(Math.sqrt(Math.pow(w-data.pageX,2)+Math.pow(0-data.pageY,2)), Math.sqrt(Math.pow(0-data.pageX,2)+Math.pow(h-data.pageY,2)))
      );
      const scale = (max / 500)*1.0;
      TweenMax.set(this.circle, {scaleX:0, scaleY:0});
      this.circle.show();
      TweenMax.to(this.circle, 0.6, {scaleX:scale, scaleY:scale, ease:Power3.easeOut, onComplete:()=>{
        TweenMax.delayedCall(0.1, ()=>{
          location.href = data.href;
        });
      }});
    }
    //TOP以外への遷移
    else
    {
      TweenMax.to(this.white, 0.3, {opacity:1, ease:Power0.easeNone, onComplete:()=>{
        location.href = data.href;
      }});
    }

    //フェーダー表示
    this.fader.show();
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	リサイズ
	// ---------------------------------------------------------------------------------------------------------

  /**
   * ウィンドウリサイズ
   */
	onResize(){

    switch (this.status) {
      case 0:
        const w = this.fader.width();
        const h = this.fader.height();
        const max = Math.max(w, h);
        const scale = (max / 1000)*1.5;
        TweenMax.set(this.circle, {scaleX:scale, scaleY:scale});
        break;
      default:
    }
	}

  // ---------------------------------------------------------------------------------------------------------
}

export default Fader;
