/**
 * Cherry
 */
class Cherry extends PIXI.Container{

  // ---------------------------------------------------------------------------------------------------------
	// --	Cherry
	// ---------------------------------------------------------------------------------------------------------

  constructor(color = 0xE60027) {

    super();

    //変数
    this.rot = 63.5;
    this.rotSpeed = 0;
    this.bounceFlag = false;
    this.rollingFlag = false;
    this.step = 0;

    //回転用コンテナ
    this.container = new PIXI.Container();
    this.addChild(this.container);


    //右
    this.rightContainer = new PIXI.Container();
    this.container.addChild(this.rightContainer);

    this.rightCircle = new PIXI.Graphics();
    this.rightCircle.beginFill(color);
    this.rightCircle.drawCircle(0, 0, 33);
    this.rightCircle.endFill();
    this.rightCircle.x = 130;
    this.rightCircle.y = 6;
    this.rightContainer.addChild(this.rightCircle);

    this.rightBar = new PIXI.Graphics();
    this.rightBar.beginFill(color);
    this.rightBar.drawRect(-130, 0, 230, 10);
    this.rightBar.endFill();
    this.rightContainer.addChild(this.rightBar);

    let rightMask = new PIXI.Graphics();
    rightMask.beginFill(0x000000);
    rightMask.drawRect(0, -100, 300, 300);
    rightMask.endFill();
    this.addChild(rightMask);
    this.rightContainer.mask = rightMask;

    this.rightContainer.rotation = this.rot * (Math.PI / 180);

    //左
    this.leftContainer = new PIXI.Container();
    this.container.addChild(this.leftContainer);

    this.leftCircle = new PIXI.Graphics();
    this.leftCircle.beginFill(color);
    this.leftCircle.drawCircle(0, 0, 33);
    this.leftCircle.endFill();
    this.leftCircle.x = -130;
    this.leftCircle.y = 6;
    this.leftContainer.addChild(this.leftCircle);

    this.leftBar = new PIXI.Graphics();
    this.leftBar.beginFill(color);
    this.leftBar.drawRect(-100, 0, 230, 10);
    this.leftBar.endFill();
    this.leftContainer.addChild(this.leftBar);

    let leftMask = new PIXI.Graphics();
    leftMask.beginFill(0x000000);
    leftMask.drawRect(-300, -100, 300, 300);
    leftMask.endFill();
    this.addChild(leftMask);
    this.leftContainer.mask = leftMask;

    this.leftContainer.rotation = -this.rot * (Math.PI / 180);
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	スプラッシュの準備
	// ---------------------------------------------------------------------------------------------------------

  readyForSplash(){

    this.rot = 10;
    this.rightContainer.rotation = this.rot * (Math.PI / 180);
    this.leftContainer.rotation = -this.rot * (Math.PI / 180);

    this.rightCircle.x = 0;
    this.leftCircle.x = 0;

    this.rightBar.x = -130;
    this.leftBar.x = 130;
  }

  showSplash(){

    TweenMax.to(this.rightCircle, 0.3, {x:130, ease:Back.easeOut.config(1.7)});
    TweenMax.to(this.leftCircle, 0.3, {x:-130, ease:Back.easeOut.config(1.7)});
    TweenMax.to(this.rightBar, 0.3, {x:0, ease:Back.easeOut.config(1.7)});
    TweenMax.to(this.leftBar, 0.3, {x:0, ease:Back.easeOut.config(1.7)});

    this.step = 1;
    this.rotSpeed = 0;
    this.bounceFlag = true;
    this.bounceCount = 0;

    // TweenMax.to(this.rightCircle, 3.3, {tint:0xE60027, ease:Power0.easeNone});
    // TweenMax.to(this.rightCircle, 1.3, {tint:0x000000, ease:Back.easeOut.config(1.7)});
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	指示
	// ---------------------------------------------------------------------------------------------------------

  /**
   * はずませる
   */
  bound(){

    if(this.bounceFlag) return;

    this.step = 1;

    this.rotSpeed = -8;
    this.bounceFlag = true;
    this.bounceCount = 0;
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	指示
	// ---------------------------------------------------------------------------------------------------------

  /**
   * 更新
   */
  update(){

    switch (this.step) {
      case 0:
        break;
      case 1:
        if(this.bounceFlag)
        {
          this.rotSpeed += 1;
          this.rot += this.rotSpeed;

          if(this.rot >= 74 && this.rotSpeed > 0)
          {
            this.rotSpeed *= -0.8;
            this.rot = 74;
            if(++this.bounceCount >= 2)
            {
              this.bounceFlag = false;
            }
          }
        }
        else
        {
          this.rot += (63.5 - this.rot)/3;
        }
        //角度をアップデート
        this.rightContainer.rotation = this.rot * (Math.PI / 180);
        this.leftContainer.rotation = -this.rot * (Math.PI / 180);
        break;
      case 2:
        //角度をアップデート
        this.rightContainer.rotation = this.rot * (Math.PI / 180);
        this.leftContainer.rotation = -this.rot * (Math.PI / 180);
        break;
      default:

    }

  }

  // ---------------------------------------------------------------------------------------------------------
}

export default Cherry;
