import Cherry from './pixi/Cherry.js';
let EventEmitter = require("events").EventEmitter;

class Loader extends EventEmitter
{
  // ---------------------------------------------------------------------------------------------------------
	// --	SmallIcon
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    super();

    //DOM参照
    this.loader = $("#loader");

    //変数
    this.isLoaded = false;

    //HOMEかどうかのフラグ
    this.isHome = ($("#home").length > 0);

    //HOMEの時はローディングアニメーション再生
    if(this.isHome)
    {
      this.initLoadingAnimation();
    }
  }

  /**
   * ローディングアニメーション初期化
   */
  initLoadingAnimation()
  {
    //アプリケーション
		this.pixi = new PIXI.Application(1, 1, { transparent:true, forceCanvas:true});
    this.pixi.ticker.add(this.update.bind(this));

    //VIEW追加
    this.loader.find("div.loader-canvas-container").append(this.pixi.view);

    //背景配置
    this.bgContainer = new PIXI.Container();
    this.pixi.stage.addChild(this.bgContainer);

    this.bg = new PIXI.Graphics();
    this.bg.beginFill(0xE60027);
    this.bg.drawCircle(0, 0, 100);
    this.bg.endFill();
    this.bgContainer.addChild(this.bg);

    //チェリー配置
    this.cherry = new Cherry(0xFFFFFF);
    this.cherry.scale.set(0.6, 0.6);
    this.pixi.stage.addChild(this.cherry);

    //テキスト初期化
    this.loader.find("p").lettering();
    this.loader.find("p >span").each(function(index){
      var w = $(this).width();
      var h = $(this).height();
      $(this).css("width", w+"px");
      $(this).css("height", h+"px");
      $(this).css("overflow", "hidden");
      let str = $(this).text();
      $(this).empty();
      $(this).append("<span>" + str + "</span>");
      let span = $(this).find(">span");
    });

    //キャンバスサイズ
    this._updateCanvas = this.updateCanvasSize.bind(this);
    $(window).resize(this._updateCanvas);
    this.updateCanvasSize.bind(this)();

    //アクション
    this.playAction.bind(this)();
  }


  /**
   * ローディングアニメーション
   */
  playAction()
  {
    //ロード済み
    if(this.isLoaded)
    {
      this.emit("finish-loading");
      return;
    }

    //テキストアニメーション
    let delay = 0;
    this.loader.find("p >span").each(function(index){

      var w = $(this).width();
      var h = $(this).height();

      delay = 0.04*index;

      let char = $(this).find(">span");
      TweenMax.to(char, 0.2, {y:h, delay:delay, onComplete:()=>{
        TweenMax.set(char, {y:-h});
        TweenMax.to(char, 0.2, {y:0});
      }});
    });

    //さくらんぼバウンド
    this.cherry.bound();

    //ループ処理
    TweenMax.delayedCall(1.5, this.playAction.bind(this));
  }

  //キャンバスサイズをアップデート
  updateCanvasSize()
  {
  	//Domサイズ更新
    this.canvasWidth = this.loader.width();
    this.canvasHeight = this.loader.height();

    //レンダラーのサイズを変更
		this.pixi.renderer.resize(this.canvasWidth*2, this.canvasHeight*2);

    //チェリーの位置
    this.cherry.x = this.canvasWidth;
    this.cherry.y = this.canvasHeight-100;

    //背景を中央に揃える
    this.bgContainer.x = this.canvasWidth;
    this.bgContainer.y = this.canvasHeight;

    //背景の大きさ
    let distance = Math.sqrt(Math.pow((0 - this.canvasWidth),2) + Math.pow((0 - this.canvasHeight),2));
    let s = (distance/100);
    this.bg.scale.set(s, s);
	}

  /**
   * アップデート
   */
  update()
  {
    if(this.cherry) this.cherry.update();
  }

  /**
   * リーブの準備
   */
  readyForLeave()
  {
    //フラグを立てる
    this.isLoaded = true;

    //HOME以外はすぐにイベント発行
    if(!this.isHome)
    {
      this.emit("finish-loading");
    }
  }

  /**
   * リーブ
   */
  leave()
  {
    //HOMEは赤丸アニメーション
    if(this.isHome)
    {
      //CHERRYとテキストフェードアウト
      this.cherry.cacheAsBitmap = true;
      TweenMax.to(this.cherry, 0.2, {alpha:0, ease:Power0.easeNone});
      TweenMax.to(this.loader.find("p"), 0.2, {opacity:0, ease:Power0.easeNone});

      //FVの時は背景移動
      this.isSP = (window.innerWidth <= 768);
      let duration = (this.isSP)?0.4:0.4;
      let delay = (this.isSP)?0.4:0.2;

      //HOMEの場合
      let logoY = 0;
      if($("body").is("#home"))
      {
        logoY = $("#canvas-logo-container").offset().top - $(window).scrollTop();
        if(logoY > 0)
        {
          let adjustY = (this.isSP)?40:62;
          let opY = logoY*2 + adjustY;
          TweenMax.to(this.bgContainer, duration, {y:opY, ease:Power2.easeIn, delay:delay});
        }
      }
      //それ以外
      else
      {
        let logoY = $(window).innerHeight()/2;
      }

      //背景縮小
      TweenMax.to(this.bgContainer.scale, duration, {x:0, y:0, ease:Power2.easeIn, delay:delay, onComplete:()=>{
        this.pixi.destroy();
        this.loader.remove();
        $(window).unbind("resize", this._updateCanvas);
        this.emit("loader-leaved");
      }});
    }
    //HOME以外は白フェードイン
    else
    {
      TweenMax.to(this.loader, 0.3, {opacity:0, ease:Power0.easeNone, onComplete:()=>{
        this.loader.remove();
        this.emit("loader-leaved");
      }});
    }
  }


  // ---------------------------------------------------------------------------------------------------------
}

export default Loader;
